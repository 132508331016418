import styled from "@emotion/styled"

import commonStyles from "../lib/commonStyles"

const SectionHeading = styled.h2`
  color: ${props => (props.color ? "grey" : commonStyles.colors.darkGreen)};
  font-family: ${commonStyles.fonts.script};
  font-size: 60px;
  font-weight: 400;

  @media (max-width: 1000px) {
    text-align: center;
  }
  @media (max-width: 700px) {
    font-size: 55px;
  }
`

export default SectionHeading
