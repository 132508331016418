import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import commonStyles from "../lib/commonStyles"

const ButtonLink = styled(props => <Link {...props} />)`
  color: ${commonStyles.colors.darkGreen};
  font-family: ${commonStyles.fonts.script} !important;
  font-size: 40px;
  background-color: white;
  padding: 2px 22px;
  border-radius: 7px;
  box-shadow: 2.2px 0 0 4px darkgray;

  @media (max-width: 1030px) {
    font-size: 36px;
  }

  @media (max-width: 358px) {
    font-size: 30px;
  }

  @media (max-width: 330px) {
    font-size: 26px;
  }
`

export default ButtonLink
