import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Hero from "../components/home/hero"
import About from "../components/home/about"
import Destinations from "../components/home/destinations"
const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <Hero />
      <main>
        <About />
        <Destinations />
      </main>
    </Layout>
  </>
)

export default IndexPage
