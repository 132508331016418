import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SectionHeading from "../SectionHeading"
import commonStyles from "../../lib/commonStyles"

import ButtonLink from "../ButtonLink"
const Section = styled.section`
  background-color: ${commonStyles.colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 200px;

  @media (max-width: 844px) {
    padding: 75px 0;
  }
`

const FiguresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
`

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 80px;

    @media (max-width: 1335px) {
      margin-right: 5vw;
    }
  }
`

const MobileFigure = styled(props => <Figure {...props} />)`
  margin: 0 !important;
`

const ImgContainer = styled.div`
  border: 5px solid white;
  border-radius: 50%;
  box-shadow: 5px 0 5px 1px rgba(0, 0, 0, 0.2);
  width: 300px;
  height: 300px;
  margin-bottom: 20px;

  @media (max-width: 1530px) {
    width: 20vw;
    height: 20vw;
  }
`

const MobileImgContainer = styled(props => <ImgContainer {...props} />)`
  width: 300px;
  height: 300px;

  @media (max-width: 900px) {
    width: 450px;
    height: 450px;
  }

  @media (max-width: 550px) {
    width: 80vw;
    height: 80vw;
  }
`

const FigureImg = styled(props => <Img {...props} />)`
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Caption = styled.figcaption`
  font-family: ${commonStyles.fonts.sans};
  color: #6b6b6b;
  text-align: center;
  z-index: 10;
`

const CaptionPart = styled.span`
  display: block;
`

function Destinations() {
  const data = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "destination-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(relativePath: { eq: "destination-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      third: file(relativePath: { eq: "destination-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fourth: file(relativePath: { eq: "destination-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const items = [
    {
      img: data.first.childImageSharp.fluid,
      alt: "Picture of Agra city in India",
      city: "Agra",
      country: "India",
      photographer: "Paul Dufour",
    },
    {
      img: data.second.childImageSharp.fluid,
      alt: "Picture of Paris in India",
      city: "Paris",
      country: "France",
      photographer: "Jacob Peters-Lehm",
    },
    {
      img: data.third.childImageSharp.fluid,
      alt: "Picture of Bangkok city in Thailand",
      city: "Bangkok",
      country: "Thailand",
      photographer: "Florian Wehde",
    },
    {
      img: data.fourth.childImageSharp.fluid,
      alt: "Picture of Cape Town city in South Africa",
      city: "Cape Town",
      country: "South Africa",
      photographer: "John O'Nolan",
    },
  ]

  const [viewportWidth, setViewportWidth] = useState(0)
  const [currentItem, setCurrentItem] = useState(0)

  useEffect(() => {
    setViewportWidth(
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    )
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem(currentItem => (currentItem === 3 ? 0 : currentItem + 1))
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const itemsJsx = items.map((item, index) => {
    return (
      <Figure key={`img-container-${index}`}>
        <ImgContainer title={`Photo by ${item.photographer} on Unsplash`}>
          <FigureImg fluid={item.img} alt={item.alt} />
        </ImgContainer>
        <Caption>
          <CaptionPart>{item.city},</CaptionPart>
          <CaptionPart>{item.country}</CaptionPart>
        </Caption>
      </Figure>
    )
  })

  return (
    <Section>
      <SectionHeading>Free this weekend?</SectionHeading>
      <div style={{ marginBottom: "50px" }}></div>
      {viewportWidth > 900 ? (
        <FiguresContainer>{itemsJsx}</FiguresContainer>
      ) : (
        <>
          <MobileFigure key={`slideshow-${currentItem}`}>
            <MobileImgContainer>
              <FigureImg
                fluid={items[currentItem].img}
                alt={items[currentItem].alt}
              />
            </MobileImgContainer>
            <Caption>
              <CaptionPart>{items[currentItem].city},</CaptionPart>
              <CaptionPart>{items[currentItem].country}</CaptionPart>
            </Caption>
          </MobileFigure>
          <div style={{ marginBottom: "50px" }}></div>
        </>
      )}
      <ButtonLink to="/contact">Contact us!</ButtonLink>
    </Section>
  )
}

export default Destinations
