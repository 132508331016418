import React from "react"
import styled from "@emotion/styled"

import SectionHeading from "../SectionHeading"
import BigMargin from "../BigMargin"
import SmallMargin from "../SmallMargin"
import ExtraSmallMargin from "../ExtraSmallMargin"

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 18vw;

  @media (max-width: 1000px) {
    padding: 100px 10vw;
  }
`

const TxtContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Txt = styled.p`
  font-size: 20px;
  line-height: 1.4;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`

const CustomSmallMargin = styled(props => <SmallMargin {...props} />)`
  margin-bottom: 30px;
`
function About() {
  return (
    <Section>
      <SectionHeading>About us</SectionHeading>
      <BigMargin />
      <TxtContainer>
        <div>
          <Txt>Who are we?</Txt>
          <ExtraSmallMargin />
          <Txt>
            Holiday Birds was founded in 2016, operating mainly in Rodrigues as
            a Tour Operator. We have been organising tours in rent vehicles in
            Mauritius for a long time. We are always looking forward to service
            our clients best; that's why we now offer holiday packages that
            allow you to travel around the most beautiful places in the world.
            We have a global team that will ensure your needs are met wherever
            you are in the world.
          </Txt>
        </div>
        <CustomSmallMargin />
        <div>
          <Txt>Why choose us?</Txt>
          <ExtraSmallMargin />
          <Txt>
            Local guides - There's no better guide than a local. Our team is
            made up of locals who are always eager to make tourists discover the
            best of their locality. Our local guides will tell you everything
            you need to know about the different places of interest so that you
            have the best experiences during your tours.
            <br />
            <br />
            Comfort - We care about your comfort, that is why, we keep renewing
            our fleet of vehicles and invest in dedicated staffs to take care of
            cleaning and maintenance.
            <br />
            <br />
            Tailor-made packages anytime, anywhere - At Holiday Birds, we know
            that each person is different and has different wishes. It is for
            this reason, that we took our time to build up a port-folio of the
            most amazing destinations, to help our clients in fulfilling their
            dreams.
          </Txt>
        </div>
      </TxtContainer>
    </Section>
  )
}

export default About
