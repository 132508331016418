import React, { useState, useEffect } from "react"
import { keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Transition } from "react-transition-group"
import Navbar from "../navbar"
import commonStyles from "../../lib/commonStyles"

const Container = styled.header`
  position: relative;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const HeroBg = styled(props => <Img {...props} />)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`
const Heading = styled.h1`
  font-family: ${commonStyles.fonts.script};
  color: white;
  font-size: 100px;
  font-weight: 400;
  align-self: center;
  transform: translateY(-30px);
  animation: ${fadeIn} 0.3s ease;
  @media (max-width: 1400px) {
    font-size: 7.5vw;
  }

  @media (max-width: 800px) {
    font-size: 9vw;
  }

  @media (max-width: 500px) {
    font-size: 10vw;
  }

  @media (max-width: 400px) {
    transform: translateY(0);
  }
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`

const Txt = styled.p`
  font-size: 20px;
  color: white;
`

const IconsList = styled.ul`
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    order: -1;
    padding-bottom: 20px;
  }
`

const IconItem = styled.li`
  cursor: pointer;
  &:not(:last-child) {
    padding-right: 15px;
  }
`
const CarouselIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${props =>
    props.selected ? commonStyles.colors.lightGreen : "white"};
  transition: background-color 0.3s ease;
`
function Hero() {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroTablet: file(relativePath: { eq: "home-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroPhone: file(relativePath: { eq: "home-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const responsiveHeroBgs = [
    data.hero.childImageSharp.fluid,
    {
      ...data.heroTablet.childImageSharp.fluid,
      media: `(max-width: 800px)`,
    },
    {
      ...data.heroPhone.childImageSharp.fluid,
      media: `(max-width: 400px)`,
    },
  ]

  const [carousel, setCarousel] = useState(0)
  const [animate, setAnimate] = useState(true)
  const [navFixed, setNavFixed] = useState(false)
  const [navHidden, setNavHidden] = useState(false)
  const [navAnimated, setNavAnimated] = useState(false)
  const [previousScrollTop, setPreviousScrollTop] = useState(0)

  function toggleAnimation() {
    setAnimate(!animate)
    setTimeout(() => setAnimate(true), 320)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCarousel(carousel => (carousel === 3 ? 0 : carousel + 1))
      toggleAnimation()
    }, 4000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    function onWindowScroll() {
      if (window.scrollY >= 602) {
        setNavFixed(true)

        setTimeout(() => setNavAnimated(true), 2000)
        const currentScrollTop =
          window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollTop > previousScrollTop) {
          setNavHidden(true)
        } else {
          setNavHidden(false)
        }
        const prevTop = currentScrollTop <= 0 ? 0 : currentScrollTop
        setPreviousScrollTop(prevTop)
      } else {
        setNavFixed(false)
        setNavAnimated(false)
      }
    }
    window.addEventListener("scroll", onWindowScroll)
    return () => window.removeEventListener("scroll", onWindowScroll)
  })

  const icons = []
  for (let i = 0; i < 4; i++) {
    const elem = (
      <IconItem key={`carousel-item-${i}`}>
        <CarouselIcon
          selected={carousel === i ? true : false}
          onClick={() => {
            setCarousel(i)
            toggleAnimation()
          }}
        ></CarouselIcon>
      </IconItem>
    )
    icons.push(elem)
  }

  const headings = [
    "Car Rental Services",
    "Holiday Packages",
    "Guided Tours",
    "Airport Transfers",
  ]

  return (
    <Container>
      <Navbar
        fixed={navFixed}
        animated={navAnimated}
        hidden={navHidden}
        variant="home"
        page="home"
      />
      <HeroBg fluid={responsiveHeroBgs} />
      <Transition in={animate} timeout={300} appear>
        <Heading key={`heading-${headings.indexOf(headings[carousel])}`}>
          {headings[carousel]}
        </Heading>
      </Transition>
      <BottomContainer>
        <Txt>your travel partner in Mauritius and Rodrigues</Txt>
        <IconsList>{icons}</IconsList>
      </BottomContainer>
    </Container>
  )
}

export default Hero
